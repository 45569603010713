import colors from 'vuetify/lib/util/colors'

export const light = {
    appBar: '#f7931a', // make this the alias color for a user.
    boxes: colors.blue.accent2,
    codeBackground: colors.grey.lighten5,
    fancyText: colors.blue.darken1,
    themeBlack: '#000000',
    themeWhite: '#FFFFFF',
}

export const dark = {
    appBar: '#f7931a',
    boxes: colors.blue.darken4,
    codeBackground: colors.grey.darken3,
    fancyText: colors.green.base,
    primary: colors.green.darken1,
    secondary: colors.indigo.base,
    themeBlack: '#FFFFFF',
    themeWhite: '#000000',
}

export const switchTheme = (vuetify) => {
    let mode = localStorage.getItem('mode');
    if (mode && mode === "light") {
        mode = "dark";
        vuetify.theme.dark = true;
    } else {
        mode = "light";
        vuetify.theme.dark = false;
    }
    localStorage.setItem('mode', mode);
}
