<template>
    <div class="text-center">
        <v-layout justify-center class="mt-0 mt-md-10">
            <div class="font-weight-bold mt-9 mr-1" v-bind:class="titleClass">Public</div>
<!--            <div class="font-weight-bold mt-md-6 mr-0 bitcoin display-2">₿</div>-->
            <img class="mt-8" alt="BTC Logo" src="../assets/images/bitcoin-logo.png" :height="iconSize" :width="iconSize">
            <div class="font-weight-bold mt-9 ml-1" v-bind:class="titleClass">Money</div>
        </v-layout>
<!--        <span class="title">Promoting the good of the people by maintaining Bitcoin tools and services.</span>-->
        <v-layout justify-center class="mt-4">
            <span v-bind:class="lightningClass" class="yellow--text mr-2">⚡</span>
            <span v-bind:class="subTitleClass" class="mt-2 text--white">Lightning Service Provider</span>
            <span v-bind:class="lightningClass" class="yellow--text ml-2">⚡</span>
        </v-layout>
        <v-layout justify-center class="mt-6 mt-md-16">
            <v-btn color="primary" v-on:click="navigateOpenChannel">Open Channel
              <v-icon right dark class="pb-1">
                mdi-access-point-network
              </v-icon>
            </v-btn>
        </v-layout>
<!--        <v-col class="pt-10 pb-0" md="6" offset-sm="3">-->
<!--            <search-box placeholder="Search - Alias / Public Key / Channel ID / Invoice"/>-->
<!--        </v-col>-->
        <v-col md="8" offset-sm="2">
            <v-card class="mt-4 mt-md-10" color="boxes">
                <v-container>
                    <h1 class="display-1 white--text ma-1 mb-4 d-none d-md-block">Lightning Network Statistics Observed by public.money</h1>
                    <h1 class="headline white--text mt-0 d-md-none">Lightning Network Statistics</h1>
                    <h1 class="headline white--text mt-0 mb-2 d-md-none">Observed by public.money</h1>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Nodes
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Number of nodes in the network</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="display-1">
                                    {{nodeCount}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Public Channels
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Number of publicly advertised channels in the network</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="display-1">
                                    {{channelCount}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Public Capacity
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Total capacity of all public channels</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="display-1">
                                    <span class="display-1">{{totalCapacity}}</span><span class="headline ml-1">BTC</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Average Time Lock
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Average minimum number of blocks required to be added to the expiry of HTLCs</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text>
                                    <span class="display-1">{{averageTimeLockDelta}}</span><span class="headline ml-1">Blocks</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Average Base Fee
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Average base fee to send a transaction through a channel</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text>
                                    <span class="display-1">{{ averageFeeBase }}</span><span class="headline ml-1">msats</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Average Fee Rate
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Average fee rate applied to the amount being sent through a channel</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="display-1">
                                    <span class="display-1">{{averageFeeRate}}</span><span class="headline ml-1">%</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                       <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Average Min HTLC
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Average minimum HTLC size</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="display-1">
                                    <span class="display-1">{{averageMinHtlc}}</span><span class="headline ml-1">msats</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card outlined v-bind:loading="loading" loader-height="2">
                                <v-card-title class="title justify-center text-break">
                                    Average Max HTLC
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pl-2" small color="primary" v-on="on">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Average maximum HTLC size</span>
                                    </v-tooltip>
                                </v-card-title>
                                <v-card-text class="display-1">
                                    <span class="display-1">{{averageMaxHtlc}}</span><span class="headline ml-1">BTC</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="justify-end">
                        <div class="white--text mr-4 caption">Last Update: {{timestamp}}</div>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
    </div>
</template>

<script>
    // import SearchBox from "./SearchBox";

    export default {
        name: 'home',
        // components: {SearchBox},
        metaInfo: {
            title: 'Home | Public Money',
        },
        data: function() {
            return {
              pubicKey: "0217000d828fba001ea1ba8a9caffc5801304ad7d71abaf56f79350740e2a73797",
              qrValue: '',
              nodeCount: 0,
              channelCount: 0,
              totalCapacity: 0,
              averageFeeBase: 0,
              averageFeeRate: 0,
              averageMinHtlc: 0,
              averageMaxHtlc: 0,
              averageTimeLockDelta: 0,
              timestamp: 0,
              loading: "blue-grey lighten-2"
            };
        },
        methods: {
            copy: function() {
              this.$copyText(`${this.pubicKey}@${this.selectedAddress}`);
            },
            showQr: function () {
              this.qrValue = `${this.pubicKey}@${this.selectedAddress}`; // .split(':')[0] ?
              this.dialog = true;
            },
            navigateOpenChannel: function () {
              this.$router.push(`/pm-nodes`);
            },
            // navigateChannels: function (pubKey) {
            //     return `${process.env.VUE_APP_API_URL}/search/${pubKey}`;
            // },
            formatCapacity: function (sats) {
                return (sats / 100000000).toFixed(3);
            }
        },
        computed: {
            titleClass () {
                return this.$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline';
            },
            subTitleClass () {
              return this.$vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1';
            },
            lightningClass () {
              return this.$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-1';
            },
            iconSize () {
              return this.$vuetify.breakpoint.mdAndUp ? '60' : '36';
            }
        },
        created: async function() {
            let response = await fetch(`/api/v1/stats/`);
            let result = await response.json();
            this.nodeCount = result.num_nodes;
            this.channelCount = result.num_channels;
            this.totalCapacity = this.formatCapacity(result.total_capacity);
            this.averageFeeBase = result.average_fee_base;
            this.averageFeeRate = result.average_fee_rate / 10000;
            this.averageMinHtlc = result.average_min_htlc;
            this.averageMaxHtlc = this.formatCapacity(result.average_max_htlc);
            this.averageTimeLockDelta = result.average_time_lock_delta;
            this.timestamp = new Date(result.timestamp).toUTCString();
            this.loading = false;
        },
    }
</script>

<style scoped src="@/assets/styles/bitcoin.css">
    img {
        margin-bottom: 20px;
        margin-top: 20px;
    }
</style>