<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="3" class="pb-4 mb-0">
                <v-layout>
                    <v-icon color="themeBlack" class="mr-2" size="25">mdi-code-braces</v-icon>
                    <h2>API</h2>
                </v-layout>
            </v-col>
        </v-row>
        <v-row>
            <v-col offset-sm="3" md="6">
                <v-expansion-panels multiple focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="fancyText--text">GET /nodes/{id}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                Return information about a specific node. Including addresses, total number of channels and total channel capacity.
                            </p>
                            <p>
                                <code class="fancyText--text codeBackground">GET https://api.public.money/nodes/{id}</code>
                            </p>
                            <p>
                                Where <code class="fancyText--text codeBackground">{id}</code> can be either a node public key or alias.
                            </p>
                            <p>
                                Example Response:
                            </p>
                            <code class="fancyText--text codeBackground">
<pre>{
    "alias": "ln1.public.money",
    "pub_key": "0313a13913428e083a4498ee4da1bf7277046ec65a0a3df7a96473ea5b54fea066",
    "last_update": 1588889339,
    "color": "#3399ff",
    "addresses": [
        {
            "id": 107636,
            "addr": "fhiy33dzvbpkhyxgzkwvg6csjas4o3qnvzb7jq4f4fjjxprkbzqbmpqd.onion:9735",
            "network": "tcp"
        },
        {
            "id": 49231,
            "addr": "54.154.147.194:9735",
            "network": "tcp"
        }
    ],
    "channelCount": 5,
    "capacity": 21050000
}</pre>
                            </code>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="fancyText--text">GET /channels?nodePubKey={pubKey}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                Return an array of channels that a node is a participant of.
                            </p>
                            <p>
                                <code class="fancyText--text codeBackground">GET https://api.public.money/channels?nodePubKey={pubKey}</code>
                            </p>
                            <p>
                                Where <code class="fancyText--text codeBackground">{pubKey}</code> is a node public key.
                            </p>
                            <p>
                                Example Response:
                            </p>
                            <code class="fancyText--text codeBackground">
<pre>[
  {
    "alias": "ln1.public.money",
    "pub_key": "0313a13913428e083a4498ee4da1bf7277046ec65a0a3df7a96473ea5b54fea066",
    "id": "691510350626816001",
    "capacity": 10000000,
    "chan_point": "6c4712c2b0b7dddf4a0cb0ec75b43fc5f4111e593459028f4e4b65076a514d98:1",
    "other_alias": "yalls.org",
    "other_pub_key": "03d06758583bb5154774a6eb221b1276c9e82d65bbaceca806d90e20c108f4b1c7"
  }
]</pre>
                            </code>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="fancyText--text">GET /channels/{id}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                Return information about a specific channel, including basic information about the participating nodes and their channel policies.
                            </p>
                            <p>
                                <code class="fancyText--text codeBackground">GET https://api.public.money/channels/{id}</code>
                            </p>
                            <p>
                                Where <code class="fancyText--text codeBackground">{id}</code> is a channel ID.
                            </p>
                            <p>
                                Example Response:
                            </p>
                            <code class="fancyText--text codeBackground">
<pre>{
    "channel": {
        "id": 115018,
        "created": "1588618214865",
        "last_update": 1588878539,
        "channel_id": "691510350626816001",
        "chan_point": "6c4712c2b0b7dddf4a0cb0ec75b43fc5f4111e593459028f4e4b65076a514d98:1",
        "node2_pub": "03d06758583bb5154774a6eb221b1276c9e82d65bbaceca806d90e20c108f4b1c7",
        "capacity": "10000000",
        "node1_pub": "0313a13913428e083a4498ee4da1bf7277046ec65a0a3df7a96473ea5b54fea066"
    },
    "node": {
        "id": 6105,
        "alias": "yalls.org",
        "color": "#f8e71c",
        "last_update": 1588876001,
        "pub_key": "03d06758583bb5154774a6eb221b1276c9e82d65bbaceca806d90e20c108f4b1c7"
    },
    "otherNode":  {
        "id": 3622,
        "alias": "ln1.public.money",
        "color": "#3399ff",
        "last_update": 1588889339,
        "pub_key": "0313a13913428e083a4498ee4da1bf7277046ec65a0a3df7a96473ea5b54fea066"
    },
    "policy": {
        "id": 130723,
        "time_lock_delta": 40,
        "fee_base_msat": "1000",
        "last_update": 1588877801,
        "max_htlc_msat": "9900000000",
        "disabled": false,
        "fee_rate_milli_msat": "2500",
        "min_htlc": "1000"
    },
    "otherPolicy": {
        "id": 130722,
        "time_lock_delta": 40,
        "fee_base_msat": "1000",
        "last_update": 1588878539,
        "max_htlc_msat": "9900000000",
        "disabled": false,
        "fee_rate_milli_msat": "1",
        "min_htlc": "1000"
    }
}</pre>
                            </code>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'api',
        metaInfo: {
            title: 'API | Public Money',
        },
    }
</script>

<style>
    .v-expansion-panel-header {
        font-weight: bold;
    }
</style>