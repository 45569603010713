<template>
  <div id="main" class="align-center">
    <v-row>
      <v-col offset-sm="3" class="pb-4 mb-0">
        <v-layout>
          <v-icon color="themeBlack" class="mr-2" size="25">mdi-access-point-network</v-icon>
          <h2>Open Channel</h2>
        </v-layout>
      </v-col>
    </v-row>
    <v-layout justify-center>
      <v-row class="mt-2 pl-2">
        <v-col offset-md="3" sm="12" md="6">
          <span>Connect to the Public Money lightning node using one of the connection options below then open a channel. The balance will be
            all on your side to start with, until you spend some of it at a merchant for example.</span><br/>
            <br/>
          <span>If you are looking for inbound liquidity please email: <a href="mailto:contact@public.money">contact@public.money</a></span>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout justify-center>
      <v-row class="mt-2">
        <v-col offset-md="3" md="6" sm="12">
          <v-card v-bind:style="{ border: `3px solid blue`}">
            <v-card-text>
              <span class="key">Public Key: </span>0217000d82...40e2a73797<br>
              <v-row class="mt-2">
                <v-col>
                  <v-select hide-details label="Address" outlined dense v-model="selectedAddress" :items="addresses"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn @click="copy()" color="primary" rounded><v-icon>mdi-content-copy</v-icon></v-btn>
                  <v-btn @click="showQr()" color="primary" rounded class="ml-2" ><v-icon>mdi-qrcode</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" width="300px" eager>
        <v-card>
          <v-card-title v-text="qrValue"/>
          <v-card-text class="text-center">
            <qr-code :value="qrValue"></qr-code>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
    import QrCode from "./QrCode";
    import {toDateTime} from "../utils/dateTime";
    // import {getNode} from "../utils/api";
    export default {
        name: 'pm-nodes',
        metaInfo: {
            title: 'Nodes | Public Money',
        },
        components: {QrCode},
        created() {
            let id = "0313a13913428e083a4498ee4da1bf7277046ec65a0a3df7a96473ea5b54fea066";
            if (id) {
                return this.fetch(id);
            }
        },
        data: function () {
            return {
                pubKey: "0217000d828fba001ea1ba8a9caffc5801304ad7d71abaf56f79350740e2a73797",
                finishedLoading: false,
                node: {addresses:[]},
                qrValue: '',
                dialog: '',
                selectedAddress: ''
            }
        },
        methods: {
            toDateTime: toDateTime,
            // fetch: async function(value) {
            //     this.node = await getNode(value);
            //     this.finishedLoading = true;
            // },
            formatCapacity: function (sats) {
                return (sats / 100000000).toFixed(3);
            },
            navigateChannels: function (pubKey) {
                this.$router.push(`/search/${pubKey}`);
            },
            showQr: function () {
                this.qrValue = `${this.pubKey}@${this.selectedAddress}`; // .split(':')[0] ?
                this.dialog = true;
            },
            copy: function () {
                this.$copyText(`${this.pubKey}@${this.selectedAddress}`);
            }
        },
        computed: {
          addresses: function() {
            return [
              "109.74.252.172:9735",
              "109.74.252.173:9735",
              "dvpcnk6mkdlnlq2ykxaninlk5gpzcrakalynqmnwtqkyazeyc3ni6yid.onion:9735"
            ];
          },
        }
    }
</script>

<style>
    #main {
        text-align: left;
    }
    .key {
        font-weight: bold;
    }
</style>