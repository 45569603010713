<template>
    <div>
        <canvas id="qr"></canvas>
    </div>
</template>

<script>
    import QRious from 'qrious';
    export default {
        name: 'qrCode',
        props: {
            value: {
                type: String,
                required: true
            }
        },
        mounted() {
            this.drawQr();
        },
        watch: {
            value: function () {
                this.drawQr();
            }
        },
        methods: {
            drawQr: function () {
                new QRious({
                    element: document.getElementById('qr'),
                    value: this.value,
                    size: 200
                });
            }
        }
    }
</script>

<style>
</style>