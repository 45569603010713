var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-layout',{staticClass:"mt-0 mt-md-10",attrs:{"justify-center":""}},[_c('div',{staticClass:"font-weight-bold mt-9 mr-1",class:_vm.titleClass},[_vm._v("Public")]),_c('img',{staticClass:"mt-8",attrs:{"alt":"BTC Logo","src":require("../assets/images/bitcoin-logo.png"),"height":_vm.iconSize,"width":_vm.iconSize}}),_c('div',{staticClass:"font-weight-bold mt-9 ml-1",class:_vm.titleClass},[_vm._v("Money")])]),_c('v-layout',{staticClass:"mt-4",attrs:{"justify-center":""}},[_c('span',{staticClass:"yellow--text mr-2",class:_vm.lightningClass},[_vm._v("⚡")]),_c('span',{staticClass:"mt-2 text--white",class:_vm.subTitleClass},[_vm._v("Lightning Service Provider")]),_c('span',{staticClass:"yellow--text ml-2",class:_vm.lightningClass},[_vm._v("⚡")])]),_c('v-layout',{staticClass:"mt-6 mt-md-16",attrs:{"justify-center":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.navigateOpenChannel}},[_vm._v("Open Channel "),_c('v-icon',{staticClass:"pb-1",attrs:{"right":"","dark":""}},[_vm._v(" mdi-access-point-network ")])],1)],1),_c('v-col',{attrs:{"md":"8","offset-sm":"2"}},[_c('v-card',{staticClass:"mt-4 mt-md-10",attrs:{"color":"boxes"}},[_c('v-container',[_c('h1',{staticClass:"display-1 white--text ma-1 mb-4 d-none d-md-block"},[_vm._v("Lightning Network Statistics Observed by public.money")]),_c('h1',{staticClass:"headline white--text mt-0 d-md-none"},[_vm._v("Lightning Network Statistics")]),_c('h1',{staticClass:"headline white--text mt-0 mb-2 d-md-none"},[_vm._v("Observed by public.money")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Nodes "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Number of nodes in the network")])])],1),_c('v-card-text',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.nodeCount)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Public Channels "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Number of publicly advertised channels in the network")])])],1),_c('v-card-text',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.channelCount)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Public Capacity "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Total capacity of all public channels")])])],1),_c('v-card-text',{staticClass:"display-1"},[_c('span',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.totalCapacity))]),_c('span',{staticClass:"headline ml-1"},[_vm._v("BTC")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Average Time Lock "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Average minimum number of blocks required to be added to the expiry of HTLCs")])])],1),_c('v-card-text',[_c('span',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.averageTimeLockDelta))]),_c('span',{staticClass:"headline ml-1"},[_vm._v("Blocks")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Average Base Fee "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Average base fee to send a transaction through a channel")])])],1),_c('v-card-text',[_c('span',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.averageFeeBase))]),_c('span',{staticClass:"headline ml-1"},[_vm._v("msats")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Average Fee Rate "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Average fee rate applied to the amount being sent through a channel")])])],1),_c('v-card-text',{staticClass:"display-1"},[_c('span',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.averageFeeRate))]),_c('span',{staticClass:"headline ml-1"},[_vm._v("%")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Average Min HTLC "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Average minimum HTLC size")])])],1),_c('v-card-text',{staticClass:"display-1"},[_c('span',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.averageMinHtlc))]),_c('span',{staticClass:"headline ml-1"},[_vm._v("msats")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading,"loader-height":"2"}},[_c('v-card-title',{staticClass:"title justify-center text-break"},[_vm._v(" Average Max HTLC "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2",attrs:{"small":"","color":"primary"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Average maximum HTLC size")])])],1),_c('v-card-text',{staticClass:"display-1"},[_c('span',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.averageMaxHtlc))]),_c('span',{staticClass:"headline ml-1"},[_vm._v("BTC")])])],1)],1)],1),_c('v-row',{staticClass:"justify-end"},[_c('div',{staticClass:"white--text mr-4 caption"},[_vm._v("Last Update: "+_vm._s(_vm.timestamp))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }