<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="3" class="pb-4 mb-0">
                <v-layout>
                    <v-icon color="themeBlack" class="mr-2" size="25">mdi-frequently-asked-questions</v-icon>
                    <h2>FAQ</h2>
                </v-layout>
            </v-col>
        </v-row>
        <v-row>
            <v-col offset-sm="3" md="6">
                <v-expansion-panels multiple focusable v-model="panels">
                    <v-expansion-panel id="whatisbitcoin">
                        <v-expansion-panel-header class="fancyText--text">What is Bitcoin?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                Bitcoin is a new global currency that anyone can use without permission. It is native to the internet and with its foundations in computer code,
                                mathematics and cryptography. Its architecture is technically and politically decentralised. This means that there is no central point of control and
                                it is resilient to corruption and manipulation. Bitcoin has a fixed supply of 21 million BTC with an inflation rate that is reduced by 50% every four years (210000 blocks).
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel id="whatisablock">
                        <v-expansion-panel-header class="fancyText--text">What is a Block?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                A block is a batch of transactions. They can only be created by special nodes on the network called miners, that expend capital in order
                                to compete to solve a mathematical puzzle (Proof of Work). The blocks are created roughly every ten minutes, but the timing can vary widely
                                (between 1 and 60 minutes is usual). A transaction on the Bitcoin network is not complete until it has been included in a block.
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel id="whatislightning">
                        <v-expansion-panel-header class="fancyText--text">What is the Lighting Network?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                The Lightning Network is a new protocol designed to greatly increase the scale and utility of Bitcoin. It increases privacy, reduces transaction times,
                                and increases throughput. Unlike the Bitcoin (layer one) protocol that broadcasts every transaction to all nodes in the network the Lightning Network (layer two)
                                only sends transactions to nodes which are a party in that transaction. There are no blocks to wait for on the Lightning Network, so transactions are confirmed instantly.
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel id="whatisatransaction">
                        <v-expansion-panel-header class="fancyText--text">What is a transaction?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                A transaction is how Bitcoin is transferred from one party to another. There are some notable differences between Bitcoin and Lightning transactions.
                            </p>
                            <p>
                                On Bitcoin sending a transaction costs a fee that goes to the miners as a reward for securing the network.
                                A Bitcoin transaction can be sent to anyone else on the network without the need for intermediaries. The fees change depending on how busy
                                the network is, but the amount being sent is not a factor.
                            </p>
                            <p>
                                A Lightning transaction also has fees but these are paid to other nodes on the network that help in routing the transaction to its recipient.
                                The fees are set by each node and can vary greatly depending on many factors.
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel id="whatisanode">
                        <v-expansion-panel-header class="fancyText--text">What is a node?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                A node is a computer that runs the Bitcoin and/or Lightning software. These computers form a peer to peer network that communicate with each other about
                                other nodes, channels and send cryptographically signed transactions.
                            </p>
                            <p>
                                There are multiple implementations of the Bitcoin and Lighting software, written by people from all over the world. Although these implementations
                                may have different features or be optimised for certain use cases they all have one thing in common in that they all understand the Bitcoin protocol.
                            </p>
                            <p>
                                Nodes can be run by individuals (serving only one or a few people) or by organisations (perhaps serving thousands or millions of people). The most secure
                                way to use Bitcoin is to <b>run your own node</b> but it is a personal choice and there are many considerations.
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel id="whatisachannel">
                        <v-expansion-panel-header class="fancyText--text">What is a channel?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                A channel is a concept specific to the Lightning Network. It is a persistent connection between two nodes that contains a certain amount of Bitcoin (the <b>balance</b>)
                                that can be transmitted between the connecting nodes very quickly and at very low cost. The channels form a graph that allow for payments to be routed
                                automatically from one node to another across distant parts of the network.
                            </p>
                            <p>
                                A channel can be either public or private:
                            </p>
                            <ul>
                                <li>The details of a <b>public</b> channel are broadcast to the whole network and anyone is able
                                    to route payments through it as long as there is available capacity and the fees are affordable.
                                </li>
                                <br/>
                                <li>On the other hand, a <b>private</b> channel is kept
                                    secret between the two participating nodes and can only be used by those two nodes. Private channels are more appropriate for most users as they
                                    require little maintenance and do not broadcast information unnecessarily to the rest of the network.
                                </li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel id="whataresats">
                        <v-expansion-panel-header class="fancyText--text">What are BTC, Sats and mSats?</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="pt-4">
                                There is a maximum supply of <b>21 000 000 BTC</b>, of which about 18 000 000 have already been created.
                            </p>
                            <p>
                                Each BTC is divisible into smaller units called Sats (short for Satoshis). There are <b>100 000 000 Sats in 1 BTC</b>.
                            </p>
                            <p>
                                The Lightning Network supports an increased resolution of mSats (milli-Satoshi). There are <b>1000 mSats in 1 Sat.</b>.
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'faq',
        metaInfo: {
            title: 'FAQ | Public Money',
        },
        data: function () {
            return {
                panels: [],
                map: [
                    '#whatisbitcoin',
                    '#whatisablock',
                    '#whatislightning',
                    '#whatisatransaction',
                    '#whatisanode',
                    '#whatisachannel',
                    "#whataresats"
                ]
            }
        },
        beforeRouteUpdate: function (to) {
            this.panels = [];
            if (to.hash) {
                this.panels.push(this.map.indexOf(to.hash));
            }
        },
        created() {
            if (this.$router.currentRoute.hash) {
                this.panels.push(this.map.indexOf(this.$router.currentRoute.hash));
            }
        }
    }
</script>

<style>
    .v-expansion-panel-header {
        font-size: large;
        font-weight: bold;
    }
</style>