import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {getDomains, getIndex} from "../utils/api";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export const store = new Vuex.Store({
    state: {
        index: [],
        domains: []
    },
    actions: {
        loadIndex({commit}) {
            getIndex().then(result => {
                commit('SAVE_INDEX', result);
            }).catch(error => {
                throw new Error(`API ${error}`);
            });
        },
        loadDomains({commit}) {
            getDomains().then(result => {
                commit('SAVE_DOMAINS', result);
            }).catch(error => {
                throw new Error(`API ${error}`);
            });
        }
    },
    mutations: {
        SAVE_INDEX(state, index) {
            state.index = index;
        },
        SAVE_DOMAINS(state, domains) {
            state.domains = domains;
        }
    },
    getters: {
        search: (state) => (a) => {
            return state.index
                .filter(value => value.includes(a))
                .slice(0, 10)
        },
        searchDomains: (state) => (a) => {
            return state.domains
                .filter(value => value.includes(a))
        }
    },
    plugins: [vuexLocal.plugin]
});

let now = new Date();
let lastUpdate = Number.parseInt(window.localStorage.getItem("lastUpdate"));
if (!lastUpdate || lastUpdate < now.valueOf() - 86400000) { // one day
    store.dispatch('loadIndex');
    store.dispatch('loadDomains');
    window.localStorage.setItem("lastUpdate", now.valueOf().toString());
}
