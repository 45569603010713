<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="3" class="pb-4 mb-0">
                <v-layout>
                    <v-icon color="themeBlack" class="mr-2" size="25">mdi-download-network-outline</v-icon>
                    <h2>New Inbound Channel</h2>
                </v-layout>
            </v-col>
        </v-row>
        <div v-if="state === 'opening'">
            <v-row>
                <v-col offset-sm="3">
                    <span class="">First please make sure your node is connected to the <router-link to="/nodes/0313a13913428e083a4498ee4da1bf7277046ec65a0a3df7a96473ea5b54fea066">public.money</router-link> node.
                    You can only have one inbound channel per node.</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-sm="3" md="4" class="pr-4">
                        <v-slider dense label="Capacity" value="capacity" v-model="capacity" class="align-center" :max="max" :min="min" hide-details></v-slider>
                </v-col>
                <v-col md="2">
                    <span class="display-1 font-weight-light" v-text="capacity"></span>
                    <span class="subheading font-weight-light ml-1">sats</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-sm="3" cols="4">
                    <v-text-field v-model="pubKey" :rules="[rules.required, rules.pubKey]" label="Your Public Key" outlined></v-text-field>
                </v-col>
                <v-col md="2" class="ml-4">
                    <v-row>
                        <v-switch v-model="privateChannel" :label="`Channel Visibility: ${privateChannel ? 'Private' : 'Public'}`"></v-switch>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon class="ml-4 mt-0" small color="primary" dark v-on="on">mdi-help-circle-outline</v-icon>
                            </template>
                            <span>A public channel can be used for payment routing by any node on the network</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-sm="3" md="2" class="pt-0">
                    <v-btn color="primary" :disabled="!enableOpen" v-on:click="openChannel">Open Channel</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-if="state === 'success'">
            <v-row>
                <v-col offset-sm="3">
                    <span>Success! Please wait a few hours for the transaction to be mined.</span>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'inbound-channel',
        metaInfo: {
            title: 'Inbound Channel | Public Money',
        },
        data: function () {
            return {
                state: "opening",
                enableOpen: true,
                capacity: 100000,
                min: 1000,
                max: 1000000,
                privateChannel: true,
                pubKey: "",
                rules: {
                    required: value => !!value || 'Required',
                    pubKey: value => {
                        const pattern = /^[0-9a-f]{66}$/
                        if (pattern.test(value)) {
                            this.enableOpen = true;
                            return true;
                        } else {
                            this.enableOpen = false;
                            return 'Invalid public key';
                        }
                    },
                }
            }
        },
        methods: {
            openChannel: async function () {
                this.enableOpen = false;
                await fetch(`${process.env.VUE_APP_API_URL}/channels`, {
                    body: JSON.stringify({
                        pubKey: this.pubKey,
                        amount: this.capacity,
                        private: this.privateChannel
                    }),
                    method: "POST"
                });
                this.state = "success";
            }
        }
    }
</script>

<style>

</style>