<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="3" md="6" class="pb-4 mb-0">
                <v-layout class="mb-3">
                    <v-icon color="themeBlack" class="mr-2" size="25">mdi-web</v-icon>
                    <h2>Discover</h2>
                </v-layout>
                <span>Listed below are node operators that have set a website address as their alias. These sites have not been filtered or sorted in any way, visit at your own risk.</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col offset-sm="3" md="6">
                <v-simple-table>
                    <thead>
                        <tr>
                            <td class="font-weight-bold">Alias (External Link)</td>
                            <td class="font-weight-bold">Public Key</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="domain in $store.state.domains" :key="domain.pub_key">
                            <td><a :href="`http://${domain.alias}`" target="_blank">{{ domain.alias }}</a></td>
                            <td><router-link :to="`/nodes/${domain.pub_key}`">{{ domain.pub_key }}</router-link></td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'discover',
        metaInfo: {
            title: 'Discover | Public Money',
        },
        data: function () {
            return {
                domains: []
            }
        },
        created: async function () {
            this.loading = false;
        }
    }
</script>

<style>
    .v-expansion-panel-header {
        font-size: large;
        font-weight: bold;
    }
</style>