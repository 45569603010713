import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {light, dark} from "./theme";

let mode = localStorage.getItem('mode');
export default new Vuetify({
    theme: {
        dark: !!(mode && mode === "dark"),
        themes: { light, dark },
    },
});

Vue.use(Vuetify);