<template>
    <div>
        <div v-if="error">
            {{error}}
        </div>
        <div v-if="data" class="align-center">
            <v-row>
                <v-col offset-sm="2" class="pb-0 mb-0">
                    <v-layout>
                        <v-icon color="themeBlack" class="mr-2" size="25">mdi-lan-connect</v-icon>
                        <h2>Channel: {{data.channel.channel_id}}</h2>
                    </v-layout>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="8" offset-sm="2">
                    <v-card v-bind:style="{ border: `5px solid #448AFF`}" outlined>
                        <v-card-text>
                            <span class="key">Status:</span> {{data.channel.final_update ? "CLOSED" : "OPEN"}}<br>
                            <span class="key">Capacity:</span> {{formatCapacity(data.channel.capacity)}} BTC<br>
                            <span class="key">Channel Point:</span><a :href="toExplorerUrl(data.channel.chan_point)" target="_blank"> {{data.channel.chan_point}}</a><br>
                            <span class="key">Last Update:</span> {{toDateTime(data.channel.last_update)}}<br>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-sm="2" md="4" class="pb-0 mb-0">
                    <v-layout>
                        <v-icon color="themeBlack" class="mr-2" size="25">mdi-text-box-search-outline</v-icon>
                        <h2>Channel Policy:
                            <router-link class="ml-2" :to="`/nodes/${data.node.pub_key}`">
                                {{data.node.alias}}
                            </router-link>
                        </h2>
                    </v-layout>
                    <v-card class="mt-3" v-bind:style="{ border: `5px solid ${data.node.color}`}" outlined>
                        <v-card-text>
                            <span class="key">Pub Key:</span> {{data.node.pub_key}}<br>
                            <span class="key">Base Fee (msat):</span> {{data.policy.fee_base_msat}}<br>
                            <span class="key">Fee Rate Milli (msat):</span> {{data.policy.fee_rate_milli_msat}}<br>
                            <span class="key">Min HTLC (msat):</span> {{data.policy.min_htlc}}<br>
                            <span class="key">Max HTLC (msat):</span> {{data.policy.max_htlc_msat}}<br>
                            <span class="key">Time Lock Delta (blocks):</span> {{data.policy.time_lock_delta}}<br>
                            <span class="key">Disabled:</span> {{data.policy.disabled}}<br>
                            <span class="key">Last Update:</span> {{toDateTime(data.policy.last_update)}}<br>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col md="4" class="pb-0 mb-0">
                    <v-layout>
                        <v-icon color="themeBlack" class="mr-2" size="25">mdi-text-box-search-outline</v-icon>
                        <h2>Channel Policy:
                            <router-link class="ml-2" :to="`/nodes/${data.otherNode.pub_key}`">
                                {{data.otherNode.alias}}
                            </router-link>
                        </h2>
                    </v-layout>
                    <v-card class="mt-3" v-bind:style="{ border: `5px solid ${data.otherNode.color}`}" outlined>
                        <v-card-text>
                            <span class="key">Pub Key:</span> {{data.otherNode.pub_key}}<br>
                            <span class="key">Base Fee (msat):</span> {{data.otherPolicy.fee_base_msat}}<br>
                            <span class="key">Fee Rate Milli (msat):</span> {{data.otherPolicy.fee_rate_milli_msat}}<br>
                            <span class="key">Min HTLC (msat):</span> {{data.otherPolicy.min_htlc}}<br>
                            <span class="key">Max HTLC (msat):</span> {{data.otherPolicy.max_htlc_msat}}<br>
                            <span class="key">Time Lock Delta (blocks):</span> {{data.otherPolicy.time_lock_delta}}<br>
                            <span class="key">Disabled:</span> {{data.otherPolicy.disabled}}<br>
                            <span class="key">Last Update:</span> {{toDateTime(data.otherPolicy.last_update)}}<br>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {toDateTime} from "../utils/dateTime";
    import {getChannel} from "../utils/api";
    export default {
        name: "channels",
        metaInfo: {
            title: 'Channel | Public Money',
        },
        created: async function() {
            let id = this.$router.currentRoute.params.id;
            if (id) {
                await this.fetch(id);
            }
        },
        watch: {
            $route(to) {
                if (to.params.id) {
                    this.fetch(to.params.id);
                }
            }
        },
        data: function () {
            return {
                data: null,
                error: null,
            }
        },
        methods: {
            toDateTime: toDateTime,
            fetch: async function (value) {
                let result = await getChannel(value);
                if (result) {
                    this.data = result;
                    this.error = null;
                } else {
                    this.error = "Not found";
                    this.data = null;
                }
            },
            navigateNode: function (pubKey) {
                this.$router.push(`/nodes/${pubKey}`);
            },
            toExplorerUrl: function (channelPoint) {
                try {
                    return `https://blockstream.info/tx/${channelPoint.split(':')[0]}`;
                } catch (e) {
                    return "";
                }
            },
            formatCapacity: function (sats) {
                return (sats / 100000000).toFixed(3);
            }
        }
    }
</script>

<style scoped>
    .key {
        font-weight: bold;
    }
</style>