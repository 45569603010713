import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/veutify/vuetify';
import VueClipboard from 'vue-clipboard2';
import VueMeta from 'vue-meta';

import {store} from "./store/store";
import {router} from "./plugins/router";
require('@/assets/styles/bitcoin.css')

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(VueMeta);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// https://dzone.com/articles/how-to-make-vuejs-website-seo-friendly