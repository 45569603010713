<template>
    <div class="align-center">
        <div v-if="!data && !error">
            <div class="text-center mt-10">
                <v-progress-circular :size="70" :width="7" color="blue accent-2" indeterminate/>
            </div>
        </div>
        <div v-if="error">
            {{error}}
        </div>
        <div v-if="data" class="align-center">
            <v-row>
                <v-col offset-sm="2" class="pb-0 mb-0">
                    <v-layout>
                        <v-icon color="themeBlack" class="mr-2" size="25">mdi-server-network</v-icon>
                        <h2>Node: {{data.node.alias}}</h2>
                    </v-layout>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="8" offset-sm="2">
                    <v-card outlined v-bind:style="{ border: `5px solid ${data.node.color}`}">
                        <v-card-text>
                            <span class="font-weight-bold">Alias:</span> {{data.node.alias}}<br>
                            <span class="font-weight-bold">Public Key:</span> {{data.node.pub_key}}<br>
                            <span class="font-weight-bold">Color:</span> {{data.node.color}}<br>
                            <span class="font-weight-bold">Open Channels:</span> {{data.node.channelCount}}<br>
                            <span class="font-weight-bold">Total Public Capacity:</span> {{formatCapacity(data.node.capacity)}} BTC<br>
                            <span class="font-weight-bold">Last Update:</span> {{toDateTime(data.node.last_update)}}<br>
                            <v-row class="mt-2">
                                <v-col md="3">
                                    <v-select hide-details label="Address" outlined dense v-model="selectedAddress" :items="data.node.addresses.map(a => a.addr)"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="copy()" color="primary" rounded v-on="on"><v-icon>mdi-content-copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Connection</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="showQr()" color="primary" rounded v-on="on" class="ml-2" ><v-icon>mdi-qrcode</v-icon></v-btn>
                                        </template>
                                        <span>Show QR Code</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn :disabled="!data.node.hasDomain" color="primary" class="ml-2" rounded v-on="on" target="_blank" :href="'http://' + data.node.alias"><v-icon>mdi-web</v-icon></v-btn>
                                        </template>
                                        <span>Visit Website</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-sm="2" class="pb-0 mb-0">
                    <v-layout>
                        <v-icon color="themeBlack" class="mr-2" size="25">mdi-lan</v-icon>
                        <h2>Channels</h2>
                    </v-layout>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="8" offset-sm="2">
                    <v-card class="pt-0 pb-1" color="boxes" flat>
                        <v-tabs v-model="tab" background-color="boxes" color="white" class="pb-2" grow>
                            <v-tab>
                                <v-icon class="mr-2" size="20">mdi-lan-connect</v-icon>
                                Open
                            </v-tab>
                            <v-tab>
                                <v-icon class="mr-2" size="20">mdi-lan-disconnect</v-icon>
                                Closed
                            </v-tab>
                        </v-tabs>
                        <v-row no-gutters class="mb-2 mt-1 ml-6 mr-2">
                            <v-col cols="4" md="2">
                                <p class="key white--text text-start ma-0">Channel ID</p>
                            </v-col>
                            <v-col cols="4" md="2">
                                <p class="key white--text text-start ma-0">Alias</p>
                            </v-col>
                            <v-col cols="4" md="2">
                                <p class="key white--text text-start ma-0">Capacity</p>
                            </v-col>
                            <v-col cols="6" class="d-none d-md-block">
                                <p class="key white--text text-start ma-0">Channel Point</p>
                            </v-col>
                        </v-row>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-if="data.channels">
                                <div id="channel" v-for="(v, n) in data.channels" v-bind:key="n">
                                    <v-row dense>
                                        <v-col>
                                            <v-card outlined class="ml-2 mr-2 pa-0">
                                                <v-card-text class="pb-0 pt-0">
                                                    <v-row>
                                                        <v-col cols="4" md="2">
                                                            <p class="key text-start ma-0"><router-link :to="`/channels/${v.id}`">{{v.id}}</router-link></p>
                                                        </v-col>
                                                        <v-col cols="4" md="2">
                                                            <p class="key text-start ma-0"><router-link :to="`/nodes/${v.other_pub_key}`">{{v.other_alias}}</router-link></p>
                                                        </v-col>
                                                        <v-col cols="4" md="2">
                                                            <p class="key text-start ma-0">{{formatCapacity(v.capacity)}} BTC</p>
                                                        </v-col>
                                                        <v-col cols="6" class="d-none d-md-block">
                                                            <p class="key text-start ma-0"><a :href="toExplorerUrl(v.chan_point)" target="_blank"> {{v.chan_point}}</a></p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-tab-item>
                            <v-tab-item>
                                <div id="closedChannels" v-for="(v, n) in data.closedChannels" v-bind:key="n">
                                    <v-row dense>
                                        <v-col>
                                            <v-card outlined class="ml-2 mr-2 pa-0" color="">
                                                <v-card-text class="pb-0 pt-0">
                                                    <v-row>
                                                        <v-col cols="4" md="2">
                                                            <p class="key text-start ma-0"><router-link :to="`/channels/${v.id}`">{{v.id}}</router-link></p>
                                                        </v-col>
                                                        <v-col cols="4" md="2">
                                                            <p class="key text-start ma-0"><router-link :to="`/nodes/${v.other_pub_key}`">{{v.other_alias}}</router-link></p>
                                                        </v-col>
                                                        <v-col cols="4" md="2">
                                                            <p class="key text-start ma-0">{{formatCapacity(v.capacity)}} BTC</p>
                                                        </v-col>
                                                        <v-col cols="6" class="d-none d-md-block">
                                                            <p class="key text-start ma-0"><a :href="toExplorerUrl(v.chan_point)" target="_blank"> {{v.chan_point}}</a></p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dialog" width="300px" eager>
                <v-card>
                    <v-card-title v-text="qrValue"/>
                    <v-card-text class="text-center">
                        <qr-code :value="qrValue"></qr-code>
                    </v-card-text>
                </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {toDateTime} from "../utils/dateTime";
    import QrCode from "./QrCode";
    import {getChannels, getNode} from "../utils/api";

    export default {
        name: "nodes",
        metaInfo: {
            title: 'Search | Public Money',
        },
        components: {QrCode},
        created: async function() {
            let id = this.$router.currentRoute.params.id;
            if (id) {
                await this.fetch(id);
            }
        },
        watch: {
            $route(to) {
                if (to.params.id) {
                    this.fetch(to.params.id);
                }
            }
        },
        data: function () {
            return {
                dialog: false,
                data: null,
                error: null,
                selectedAddress: "",
                qrValue: "",
                tab: null
            }
        },
        methods: {
            toDateTime: toDateTime,
            fetch: async function (value) {
                let node = await getNode(value);
                if (node) {
                    this.error = null;
                    let channels = await getChannels(node.pub_key);
                    this.data = {node: node, channels: channels};
                    if (this.data.node.addresses.length === 1) {
                        this.selectedAddress = this.data.node.addresses[0].addr;
                    }
                    await this.loadClosedChannels();
                } else {
                    this.data = null;
                    this.error = "Node not found";
                }
            },
            loadClosedChannels: async function() {
                this.data.closedChannels = await getChannels(this.data.node.pub_key, true);
            },
            toExplorerUrl: function (channelPoint) {
                try {
                    return `https://blockstream.info/tx/${channelPoint.split(':')[0]}`;
                } catch (e) {
                    return "";
                }
            },
            showQr: function () {
                this.qrValue = `${this.data.node.pub_key}@${this.selectedAddress}`; // .split(':')[0] ?
                this.dialog = true;
            },
            copy: function () {
                this.$copyText(`${this.data.node.pub_key}@${this.selectedAddress}`);
            },
            formatCapacity: function (sats) {
                return (sats / 100000000).toFixed(3);
            }
        }
    }
</script>

<style scoped>
    .key {
        font-weight: bold;
    }
</style>