import Home from "../components/Home";
import About from "../components/About";
import Nodes from "../components/Nodes";
import Channels from "../components/Channels";
import PmNodes from "../components/PmNodes";
import InboundChannel from "../components/InboundChannel";
import Invoice from "../components/Invoice";
import Api from "../components/Api";
import Faq from "../components/Faq";
import TopTen from "../components/TopTen";
import VueRouter from "vue-router";
import Vue from "vue";
import NotFound from "../components/NotFound";
import Discover from "../components/Discover";

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/nodes/:id', component: Nodes },
    { path: '/channels/:id', component: Channels },
    { path: '/pm-nodes', component: PmNodes },
    { path: '/inbound-channel', component: InboundChannel },
    { path: '/invoice', component: Invoice },
    { path: '/invoice/:inv', component: Invoice },
    { path: '/discover', component: Discover },
    { path: '/api', component: Api },
    { path: '/faq', component: Faq },
    { path: '/top', component: TopTen },
    { path: '/not-found', component: NotFound }
];

export const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: function(to) {
        // https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
        if (to.hash) {
            return {
                selector: to.hash,
                offset: { x: 0, y: 100 }
            }
        }
    },
});

Vue.use(VueRouter);