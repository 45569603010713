<template>
<v-app>
  <v-navigation-drawer app v-model="navbar">
    <template v-slot:prepend>
      <v-list-item @click="navbarClicked('/')">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="navbarClicked('/pm-nodes')">
        <v-list-item-icon>
          <v-icon>mdi-access-point-network</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Open Channel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
<!--      <v-list-item @click="navbarClicked('/discover')">-->
<!--        <v-list-item-icon>-->
<!--          <v-icon>mdi-web</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>Discover</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--&lt;!&ndash;      <v-list-item @click="navbarClicked('/inbound-channel')">&ndash;&gt;-->
<!--&lt;!&ndash;        <v-list-item-icon>&ndash;&gt;-->
<!--&lt;!&ndash;          <v-icon>mdi-download-network-outline</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;        </v-list-item-icon>&ndash;&gt;-->
<!--&lt;!&ndash;        <v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;          <v-list-item-title>Inbound Channel</v-list-item-title>&ndash;&gt;-->
<!--&lt;!&ndash;        </v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;      </v-list-item>&ndash;&gt;-->
<!--      <v-list-item @click="navbarClicked('/top')">-->
<!--        <v-list-item-icon>-->
<!--          <v-icon>mdi-format-list-numbered</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>Top Ten</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-list-item @click="navbarClicked('/invoice')">-->
<!--        <v-list-item-icon>-->
<!--          <v-icon>mdi-text-box-search-outline</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>Decode Invoice</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--      <v-list-item @click="navbarClicked('/api')">-->
<!--        <v-list-item-icon>-->
<!--          <v-icon>mdi-code-braces</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>API</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
      <v-list-item @click="navbarClicked('/faq')">
        <v-list-item-icon>
          <v-icon>mdi-frequently-asked-questions</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="navbarClicked('/about')">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>About</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>

  <v-app-bar color="appBar" dark dense app>
    <v-app-bar-nav-icon class="themeWhite--text" @click="navbar = !navbar"/>

    <v-toolbar-title class="font-weight-bold title themeWhite--text d-none d-md-block">Public</v-toolbar-title>
<!--    <v-toolbar-title class="font-weight-bold headline themeWhite&#45;&#45;text bitcoin ml-1 mr-1">₿</v-toolbar-title>-->
    <img class="mt-0" alt="BTC Logo" src="./assets/images/bitcoin-logo.png" height="40" width="40">
    <v-toolbar-title class="font-weight-bold title themeWhite--text d-none d-lg-block">Money</v-toolbar-title>

    <v-spacer/>

<!--    <v-col cols="6" md="2" class="mt-4 pb-0 mb-0">-->
<!--      <search-box v-show="$route.path !== '/'" class="pb-0 mb-0" height="5" placeholder="Search" background-color="themeWhite" dense/>-->
<!--    </v-col>-->
    <v-btn icon color="themeWhite" v-show="$route.path !== '/'" @click="navbarClicked('/')">
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn icon color="themeBlack" @click="switchTheme()" h>
      <v-icon>mdi-circle-half-full</v-icon>
    </v-btn>
  </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-content>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-content>

  <v-footer>
    <v-spacer></v-spacer>
    <div>&copy; Public Money {{ new Date().getFullYear() }}</div>
  </v-footer>

</v-app>
</template>


<script>
  // import {search} from "./utils/search";
  // import SearchBox from "./components/SearchBox";
  import {switchTheme} from './plugins/veutify/theme';

  export default {
    name: 'app',
    // components: {SearchBox},
    metaInfo: {
      meta: [
        { name: 'description', content: 'Bitcoin Lightning Network Service Provider' },
        { name: 'keywords', content: 'Bitcoin,Lightning,Public,Money,Explorer,Network,Search,Node,Channel' }
      ]
    },
    data: function() {
      return {
        navbar: false,
        searchValue: ""
      }
    },
    methods: {
      switchTheme: function () {
        switchTheme(this.$vuetify)
      },
      navbarClicked: function (nav) {
        if (this.$router.currentRoute.path !== nav) {
          this.$router.push(nav);
        }
        this.navbar = false;
      },
      // search: async function (value) {
      //   if (value) {
      //     await search(this.$router, value);
      //   }
      //   this.searchValue = "";
      // }
    }
  }
</script>

<style scoped>
  .bitcoin {
    border:6px solid !important;
  }
  .bitcoin:after {
    padding-top:0 !important;
    padding-bottom:0 !important;
  }
  .bitcoin:before {
    padding-left:6px !important;
  }
  .bitcoin:after {
    padding-right:6px !important;
  }
  /*#app {*/
  /*  font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  /*  -webkit-font-smoothing: antialiased;*/
  /*  -moz-osx-font-smoothing: grayscale;*/
  /*}*/
</style>
