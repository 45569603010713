<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="2" class="pb-0 mb-0">
                <v-layout>
                    <v-icon color="themeBlack" class="mr-2" size="25">mdi-format-list-numbered</v-icon>
                    <h2>Top Ten Capacity Nodes</h2>
                </v-layout>
            </v-col>
        </v-row>
        <v-row>
            <v-col offset-sm="2" md="8">
                <v-card class="pt-2 pb-1" color="boxes">
                    <v-row no-gutters class="ma-0 pa-0 ml-6 mr-2">
                        <v-col cols="6" md="3">
                            <p class="key white--text text-start ma-0">Alias</p>
                        </v-col>
                        <v-col cols="3" md="2">
                            <p class="key white--text text-start ma-0">Capacity</p>
                        </v-col>
                        <v-col cols="3" md="1">
                            <p class="key white--text text-start ma-0">Channels</p>
                        </v-col>
                        <v-col cols="6" class="d-none d-md-block">
                            <p class="key white--text text-start ma-0">Public Key</p>
                        </v-col>
                    </v-row>
                    <div id="channel" v-for="(v, n) in topNodes" v-bind:key="n">
                        <v-row dense>
                            <v-col>
                                <v-card outlined class="ml-2 mr-2 pa-0">
                                    <v-card-text class="pb-0 pt-0">
                                        <v-row>
                                            <v-col cols="6" md="3">
                                                <p class="key text-start ma-0"><router-link :to="`/nodes/${v.pub_key}`">{{v.alias}}</router-link></p>
                                            </v-col>
                                            <v-col cols="3" md="2">
                                                <p class="key text-start ma-0">{{formatCapacity(v.capacity)}} BTC</p>
                                            </v-col>
                                            <v-col cols="3" md="1">
                                                <p class="key text-start ma-0">{{v.channels}}</p>
                                            </v-col>
                                            <v-col cols="6" class="d-none d-md-block">
                                                <p class="key text-start ma-0">{{v.pub_key}}</p>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {getTopTen} from "../utils/api";

    export default {
        name: 'top-ten',
        metaInfo: {
            title: 'Top Ten | Public Money',
        },
        created: async function () {
            this.topNodes = await getTopTen();
            this.loading = false;
        },
        data: function () {
            return {
                topNodes: "",
                loading: "blue-grey lighten-2",
            }
        },
        methods: {
            formatCapacity: function (sats) {
                return (sats / 100000000).toFixed(3);
            },
            navigateChannels: function (pubKey) {
                this.$router.push(`/search/${pubKey}`);
            },
        }
    }
</script>

<style>
    .key {
        font-weight: bold;
    }
</style>