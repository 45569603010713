<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="3" class="pb-4 mb-0">
                <h2>Nothing found for that query.</h2>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'not-found',
        metaInfo: {
            title: 'Not Found | Public Money',
        },
    }
</script>

<style>
</style>