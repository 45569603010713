<template>
    <div class="align-center">
        <v-row>
            <v-col offset-sm="2" class="pb-2 pb-0">
                <v-layout>
                    <v-icon color="themeBlack" class="mr-2" size="25">mdi-text-box-search-outline</v-icon>
                    <h2>Payment Invoice</h2>
                </v-layout>
            </v-col>
        </v-row>
        <div>
            <v-row class="mb-0">
                <v-col offset-sm="2" md="8" class="mb-0">
                    <v-textarea clearable outlined v-model="invoice" :error="error" :success="decoded !== ''"
                                v-on:input="decode" v-on:change="decode" placeholder="Paste invoice here..." :error-messages="errorMessages"/>
                </v-col>
            </v-row>
            <v-row v-if="decoded" class="mt-0 pt-0">
                <v-col offset-sm="2" md="8" class="pt-0">
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>Coin Type</td>
                                <td>{{decoded.coinType}}</td>
                            </tr>
                            <tr>
                                <td>Complete</td>
                                <td>{{decoded.complete}}</td>
                            </tr>
                            <tr v-show="decoded.satoshis">
                                <td>Amount (sats)</td>
                                <td>{{decoded.satoshis}}</td>
                            </tr>
                            <tr v-show="decoded.millisatoshi">
                                <td>Amount (msats)</td>
                                <td>{{decoded.millisatoshi}}</td>
                            </tr>
                            <tr>
                                <td>Payee Node Public Key</td>
                                <td>{{decoded.payeeNodeKey}}</td>
                            </tr>
                            <tr>
                                <td>Prefix</td>
                                <td>{{decoded.prefix}}</td>
                            </tr>
                            <tr>
                                <td>Recovery Flag</td>
                                <td>{{decoded.recoveryFlag}}</td>
                            </tr>
                            <tr>
                                <td>Signature</td>
                                <td>{{decoded.signature}}</td>
                            </tr>
                            <tr v-for="tag in decoded.tags" :key="tag.data.tagCode">
                                <td>Tag: {{ tag.tagName }}</td>
                                <td>{{ tag.data }}</td>
                            </tr>
                            <tr>
                                <td>Timestamp</td>
                                <td>{{decoded.timestamp}}</td>
                            </tr>
                            <tr>
                                <td>Local Timestamp</td>
                                <td>{{new Date(decoded.timestamp * 1000).toUTCString()}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import * as lightningPayReq from "bolt11";

    export default {
        name: 'invoice',
        metaInfo: {
            title: 'Invoice | Public Money',
        },
        data: function () {
            return {
                invoice: "",
                decoded: "",
                errorMessages: []
            }
        },
        created: async function() {
            this.invoice = this.$router.currentRoute.params.inv;
            this.decode();
        },
        computed: {
            error() {
                return this.invoice && !this.decoded;
            }
        },
        methods: {
            decode() {
                if (this.invoice) {
                    try {
                        this.decoded = lightningPayReq.decode(this.invoice);
                        this.errorMessages = [];
                    } catch (e) {
                        this.decoded = "";
                        this.errorMessages = ["Error decoding invoice"];
                    }
                } else {
                    this.decoded = "";
                    this.errorMessages = [];
                }
            }
        }
    }
</script>

<style>

</style>