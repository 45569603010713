export async function getIndex() {
    try {
        let response = await fetch(`${process.env.VUE_APP_API_URL}/index`);
        return response.json();
    } catch (e) {
        return [];
    }
}

export async function getNode(pubKey) {
    try {
        let response = await fetch(`${process.env.VUE_APP_API_URL}/nodes/${encodeURIComponent(pubKey)}`);
        return response.json();
    } catch (e) {
        return null;
    }
}

export async function getChannel(channelId) {
    try {
        let response = await fetch(`${process.env.VUE_APP_API_URL}/channels/${encodeURIComponent((channelId))}`);
        return response.json();
    } catch (e) {
        return null;
    }
}

export async function getChannels(pubKey, closed = false) {
    try {
        let response = await fetch(`${process.env.VUE_APP_API_URL}/channels?nodePubKey=${pubKey}&closed=${closed}`);
        return response.json();
    } catch (e) {
        return [];
    }
}

export async function getTopTen() {
    try {
        let response = await fetch(`${process.env.VUE_APP_API_URL}/top`);
        return (await response.json()).nodes;
    } catch (e) {
        return [];
    }
}

export async function getDomains() {
    try {
        let response = await fetch(`${process.env.VUE_APP_API_URL}/domains`);
        return await response.json();
    } catch (e) {
        return [];
    }
}