<template>
    <div id="about" class="text-center">
      <v-layout justify-center class="mt-10">
        <div class="font-weight-bold mt-9 mr-1" v-bind:class="titleClass">Public</div>
        <img class="mt-8" alt="BTC Logo" src="../assets/images/bitcoin-logo.png" :height="iconSize" :width="iconSize">
        <div class="font-weight-bold mt-9 ml-1" v-bind:class="titleClass">Money</div>
      </v-layout>
<!--        <div class="title">Promoting the good of the people by building Bitcoin tools and services</div><br/>-->
        <v-layout justify-center class="mt-10">
          <span v-bind:class="lightningClass" class="yellow--text mr-2">⚡</span>
          <span v-bind:class="subTitleClass" class="mt-2 text--white">Lightning Service Provider</span>
          <span v-bind:class="lightningClass" class="yellow--text ml-2">⚡</span>
        </v-layout>
        <div class="title mt-6">For all enquiries please email: <a href="mailto:contact@public.money">contact@public.money</a></div>
    </div>
</template>

<script>
    export default {
        name: 'about',
        metaInfo: {
            title: 'About | Public Money',
        },
        computed: {
            titleClass () {
              return this.$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline';
            },
            subTitleClass () {
              return this.$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline';
            },
            lightningClass () {
              return this.$vuetify.breakpoint.mdAndUp ? 'display-3' : 'display-2';
            },
            iconSize () {
              return this.$vuetify.breakpoint.mdAndUp ? '60' : '36';
            }
        },
    }
</script>

<style scoped src="@/assets/styles/bitcoin.css">

</style>